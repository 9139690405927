const parseReport = (type) => {
  switch (type) {
    case 'user':
      return '유저 신고';
    case 'feed':
      return '게시물 신고';
    case 'comment':
      return '댓글 신고';
    default:
  }
  return null;
};

export default parseReport;
