// const HOST = "http://10.99.2.125:8000";
const HOST = "https://wena.humilylab.com";

export const API = {
  // 메인
  MAIN: `${HOST}/admin_main`,
  // 유저 정보
  USER: `${HOST}/user`,
  USER_LIST: `${HOST}/user_list`,
  USER_D: `${HOST}/user_detail`,
  USER_STATUS: `${HOST}/stop_account`,
  // 포스트
  // POST_LIST: `${HOST}/post_list`,
  POST: `${HOST}/admin/feed`,
  DELETE_POST: `${HOST}/feed/edit`,
  COMMENT: `${HOST}/admin/comment`,
  DELETE_COMMENT: `${HOST}/comment/edit`,
  // 문의 내역
  INQUIRY_LIST: `${HOST}/inquiry_list`,
  INQUIRY: `${HOST}/inquiry`,
  ANSWER: `${HOST}/answer`,
  // 신고 내역
  REPORT: `${HOST}/report_list`,
  REPORT_D: `${HOST}/report_detail`,
  REPORT_CONFIRM: `${HOST}/report_status`,
  // 로그인
  SIGNIN: `${HOST}/admin_signin`,
};
