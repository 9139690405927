import React from 'react';
import { darken } from 'polished';
import styled, { css } from 'styled-components/macro';

interface propsType {
  // 버튼 배경 색 (theme.ts의 colors 변수만 사용 가능)
  color?: string;
  // 버튼 텍스트스타일 관련 속성
  fontWeight?: string;
  // fullwidth가 true일때 가능한 가장 넓은 너비를 갖게 됨
  fullWidth?: boolean;
  // outline이 true일때 color가 border color값이 되어 테투리가 있는 버튼 ui가 됨
  outline?: boolean;
  // 버튼 클릭 시 실행되는 콜백 함수
  onClick?: React.MouseEventHandler;
  // 버튼 비활성화 여부
  disabled?: boolean;
  children?: React.ReactNode;
  value?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

function Button({
  children,
  color = 'white',
  type,
  fontWeight = '700',
  fullWidth = false,
  outline = false,
  disabled = false,
  value,
  ...rest
}: propsType) {
  return (
    <StyledButton
      value={value}
      type={type}
      color={color}
      outline={outline}
      fullWidth={fullWidth}
      fontWeight={fontWeight}
      disabled={disabled}
      {...rest}
    >
      {children}
    </StyledButton>
  );
}

const colorStyles = css<{ color: string; outline: boolean }>`
  ${({ theme, color, outline }) => {
    const selected = theme.colors[color];
    return outline
      ? css`
          color: ${selected};
          background: white;
          border: 1px solid ${selected};
          &:hover {
            background: ${selected};
            color: white;
          }
        `
      : css`
          border: none;
          background: ${selected};
          color: ${selected === '#FFF' ? 'black' : 'white'};
          &:not(:disabled):hover {
            background: ${darken(0.1, selected)};
          }
          &:not(:disabled):active {
            background: ${darken(0.1, selected)};
          }
        `;
  }}
`;

const fontWeightStyles = css<{ fontWeight: string }>`
  ${({ fontWeight }) => {
    const selected = fontWeight;
    return css`
      font-weight: ${selected};
    `;
  }}
`;

const fullWidthStyle = css<{ fullWidth: boolean }>`
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
      justify-content: center;
    `}
`;

const StyledButton = styled.button<{
  color: string;
  fontWeight: string;
  fullWidth: boolean;
  outline: boolean;
}>`
  ${({ theme }) => theme.flexSet()};
  padding: 0.6rem 1rem;
  outline: none;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 4px;
  color: black;
  -webkit-tap-highlight-color: transparent;
  font-size: 1rem;
  &:disabled {
    cursor: not-allowed !important;
  }

  ${colorStyles}
  ${fontWeightStyles}
  ${fullWidthStyle}
`;

export default Button;
