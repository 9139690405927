import { darken } from 'polished';
import styled, { css } from 'styled-components';
import parseState from '../../../utils/parseState';

interface propsType {
  state: string;
}

const ReportStateBadge = ({ state }: propsType) => {
  const color = parseState(state)?.color;
  return <Badge bgc={color}>{parseState(state)?.text}</Badge>;
};

const Badge = styled.div<{ bgc?: string }>`
  ${({ theme }) => theme.flexSet()}
  height: 20px;
  padding: 2px 5px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.8rem;
  ${({ theme, bgc }) => {
    const selected = theme.colors[bgc!];
    return css`
      background-color: ${selected};
      color: ${darken(0.5, selected)};
    `;
  }};
`;

export default ReportStateBadge;
