// 게시물 상세 페이지
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import Loading from "../../components/Loading";
import Profile from "../../components/Profile";
import UserDetail from "../../components/UserDetail";
import { API } from "../../config";
import { Post, Comment } from "../../interfaces/Interfaces";

const PostDetail = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [post, setPost] = useState<Post>();
  const [comments, setComments] = useState<Array<Comment>>();
  const [showDetail, setShowDetail] = useState(0);

  const getDetail = () => {
    axios.get(`${API.POST}/${id}`).then((res) => {
      setPost(res.data.data);
    });
    axios.get(`${API.COMMENT}/${id}`).then((res) => {
      setComments(res.data.data.list);
    });
  };

  const deletePost = (id: number) => {
    let check = window.confirm("포스트를 삭제할까요?");
    if (check) {
      axios.delete(`${API.DELETE_POST}/${id}`).then((res) => {
        if (res.status == 200) {
          window.alert("삭제되었습니다.");
          navigate(-1);
        }
      });
    }
  };
  const deleteComment = (id: number) => {
    let check = window.confirm("댓글을 삭제할까요?");
    if (check) {
      axios.delete(`${API.DELETE_COMMENT}/${id}`).then((res) => {
        if (res.status == 200) {
          window.alert("삭제되었습니다.");
          setComments((prev) =>
            prev?.map((comment) => {
              if (comment.comment_id == id) {
                return { ...comment, is_deleted: true };
              }
              return comment;
            })
          );
        }
      });
    }
  };

  useEffect(() => getDetail(), [id]);

  return post ? (
    <Container>
      <ShadowBox>
        <UpperBar>
          <UserInfo
            onClick={(e) => {
              setShowDetail(post.author.user_id);
            }}
          >
            <Profile size={50} src={post.profile} />
            {showDetail == post.author.user_id && (
              <UserDetail
                id={post.author.user_id}
                onClose={() => setShowDetail(0)}
              />
            )}
            <Text>
              <p>{post.author.nickname}</p>
            </Text>
          </UserInfo>
          {!post.is_deleted && (
            <XButton onClick={() => deletePost(post.feed_id)}>❌</XButton>
          )}
        </UpperBar>
        <FlexWrapper>
          <FlexBox>
            {post.images.map((image) => (
              <ImgWrapper>
                <img src={image} />
              </ImgWrapper>
            ))}
            <div>{post.text}</div>
          </FlexBox>
          <FlexBox>
            <Text>댓글 {post.count_info.comments}개</Text>
            {comments?.map((comment) => (
              <CommentWrapper
                id={comment.comment_id.toString()}
                deleted={comment.is_deleted}
              >
                <UserInfo
                  onClick={(e) => {
                    setShowDetail(comment.comment_id);
                  }}
                >
                  <Profile size={25} src={post.profile} />
                  {showDetail == comment.comment_id && (
                    <UserDetail
                      id={post.author.user_id}
                      onClose={() => setShowDetail(0)}
                    />
                  )}
                  <Text>
                    <p>{post.author.nickname}</p>
                  </Text>
                </UserInfo>
                <p>
                  {comment.text}
                  {!comment.is_deleted && (
                    <span onClick={() => deleteComment(comment.comment_id)}>
                      ❎
                    </span>
                  )}
                </p>
              </CommentWrapper>
            ))}
          </FlexBox>
        </FlexWrapper>
      </ShadowBox>
    </Container>
  ) : (
    <Loading />
  );
};

const Container = styled.div`
  min-width: 1000px;
  padding: 10px;

  @media ${({ theme }) => theme.mobile} {
    width: 100vw;
  }
`;

const ShadowBox = styled.div`
  width: 100%;
  position: relative;
  padding: 25px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 0px 6px lightgray;

  @media ${({ theme }) => theme.mobile} {
    padding: 20px;
  }
`;

const UpperBar = styled.div`
  ${({ theme }) => theme.flexSet("space-between", "flex-start")};
`;

const FlexWrapper = styled.div`
  ${({ theme }) => theme.flexSet("center", "flex-start")};

  @media ${({ theme }) => theme.mobile} {
    flex-direction: column;
  }
`;

const FlexBox = styled.div`
  width: 50%;

  &:first-child {
    padding-bottom: 30px;
  }

  &:nth-child(2) {
    padding-left: 30px;
  }

  @media ${({ theme }) => theme.mobile} {
    width: 100%;

    &:nth-child(2) {
      padding: 20px 0px;
    }
  }
`;

const UserInfo = styled.div`
  ${({ theme }) => theme.flexSet()}
  position: relative;
  width: fit-content;
  padding: 10px 0px;
  cursor: pointer;
`;

const XButton = styled.button`
  cursor: pointer;
  margin: 5px;
`;

const Text = styled.div`
  margin: 0px 10px;
  font-size: 1rem;
  text-align: left;
  line-height: 1.25;
  font-weight: bold;
  flex-shrink: 0;
`;

const ImgWrapper = styled.div`
  max-width: 700px;
  margin-bottom: 20px;

  @media ${({ theme }) => theme.mobile} {
    width: 100%;
  }

  img {
    max-width: 100%;
  }
`;

const CommentWrapper = styled.div<{ deleted: boolean }>`
  ${({ theme }) => theme.flexSet("flex-start")}
  flex-wrap: wrap;
  color: ${(props) => (props.deleted ? "grey" : "black")};

  p {
    flex-shrink: 100;
    line-height: 1.4;

    span {
      padding-left: 10px;
      cursor: pointer;
    }
  }
`;

export default PostDetail;
