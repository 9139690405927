import axios from 'axios';
import { useEffect, useState } from 'react';
import Table from '../../../components/Table';
import { API } from '../../../config';
import { Head, Inquiry } from '../../../interfaces/Interfaces';

interface propTypes {
  count: number;
}

const InquiryPreview = ({ count }: propTypes) => {
  const [inquiries, setInquiries] = useState<Inquiry[]>([]);

  useEffect(() => {
    axios.get(`${API.INQUIRY_LIST}?page=1`).then((res) => {
      setInquiries(res.data.data.slice(0, 5));
    });
  }, []);

  return (
    <Table
      title="💬 대기 중인 답변"
      heads={HEAD_INFO}
      data={inquiries.map((inquiry) => [inquiry.title, inquiry.date])}
      totalItems={count}
    />
  );
};

export default InquiryPreview;

const HEAD_INFO: Array<Head> = [
  {
    id: 0,
    title: 'report_type',
    options: [{ id: 0, label: '제목' }],
  },
  {
    id: 1,
    title: 'created_date',
    options: [{ id: 0, label: '날짜' }],
  },
];
