// 메인페이지
import axios from 'axios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { API } from '../../config';
import InquiryPreview from './components/InquiryPreview';
import UserPreview from './components/UserPreview';
import ReportPreview from './components/ReportPreview';

const Main = () => {
  const [countObj, setCountObj] = useState({
    new_user: 0,
    new_report: 0,
    new_inquiry: 0,
  });

  useEffect(() => {
    axios.get(API.MAIN).then((res) => {
      setCountObj(res.data.data);
    });
  }, []);

  return (
    <Container>
      {/* 신규 유저 테이블 */}
      <UserPreview count={countObj.new_user} />
      {/* 처리되지 않은 신고 테이블 */}
      <ReportPreview count={countObj.new_report} />
      {/* 대기 중인 답변 테이블 */}
      <InquiryPreview count={countObj.new_inquiry} />
    </Container>
  );
};

const Container = styled.div`
  ${({ theme }) => theme.flexColumnSet()};
  gap: 4rem;
  padding-bottom: 10vh;
`;

export default Main;
