// 전체 문의내역
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import PageButtons from '../../components/PageButtons';
import StateBadge from '../../components/StateBadge';
import Table from '../../components/Table';
import { API } from '../../config';
import { Head, Inquiry } from '../../interfaces/Interfaces';
import { optionState } from '../../recoil/atom';

const InquiryList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const options = useRecoilValue(optionState);
  const [inquiries, setInquires] = useState<Inquiry[]>([]);
  const [count, setCount] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const curPage = parseInt(location.search.split('=')[1]);

  useEffect(() => {
    getInquiryList(curPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, Object.entries(options).join()]);

  const getInquiryList = (page: number) => {
    axios
      .get(API.INQUIRY_LIST, { params: { page, ...options } })
      .then((res) => {
        setCount(res.data.entire);
        setInquires(res.data.data);
        setMaxPage(res.data.max_page);
      });
  };

  return (
    <Container>
      <Table
        total={count}
        title="전체 문의"
        heads={HEAD_INFO}
        data={inquiries.map((inquiry) => [
          inquiry.id,
          <StateBadge state={inquiry.state} />,
          inquiry.title,
          inquiry.date,
          inquiry.nickname,
          inquiry.phone,
          inquiry.email,
        ])}
        toDetail={(id: number) => {
          navigate(`${id}`);
        }}
        totalItems={count}
      />
      <PageButtons
        curPage={curPage}
        maxPage={maxPage}
        prev={() => {
          if (curPage > 1) {
            navigate({
              search: `?${createSearchParams({ page: `${curPage - 1}` })}`,
            });
          }
        }}
        next={() => {
          if (curPage < maxPage) {
            navigate({
              search: `?${createSearchParams({ page: `${curPage + 1}` })}`,
            });
          }
        }}
      />
    </Container>
  );
};

const Container = styled.div``;

export default InquiryList;

const HEAD_INFO: Array<Head> = [
  {
    id: 0,
    title: 'inquiry_pk',
    options: [{ id: 0, label: 'ID' }],
  },
  {
    id: 1,
    title: 'status',
    type: 'filter',
    options: [
      {
        id: 0,
        label: '처리상태',
      },
      { id: 1, label: '전체' },
      { id: 2, label: '처리 전' },
      { id: 3, label: '완료' },
    ],
  },
  {
    id: 2,
    title: 'title',
    options: [{ id: 0, label: '제목' }],
  },
  {
    id: 3,
    title: 'create_date',
    type: 'sort',
    options: [
      { id: 0, label: '문의접수일' },
      { id: 1, label: '문의접수일 ↑' },
      { id: 2, label: '문의접수일 ↓' },
    ],
  },
  {
    id: 4,
    title: 'username',
    options: [{ id: 0, label: '닉네임' }],
  },
  {
    id: 5,
    title: 'phone',
    options: [{ id: 0, label: '휴대폰 번호' }],
  },
  {
    id: 6,
    title: 'email',
    options: [{ id: 0, label: '이메일' }],
  },
];
