// 페이지네이션을 위한 페이지 네비게이터
import styled from 'styled-components';

interface propsType {
  // 현재 페이지 넘버
  curPage: number;
  // 전체 페이지 수
  maxPage: number;
  // 이전 페이지로 이동하는 함수
  prev: React.MouseEventHandler;
  // 다음 페이지로 이동하는 함수
  next: React.MouseEventHandler;
}

const PageButtons = ({ curPage, maxPage, prev, next }: propsType) => {
  return (
    <Container>
      <Button onClick={curPage > 1 ? prev : undefined} isActive={curPage > 1}>
        〈
      </Button>
      {curPage} / {maxPage}
      <Button
        onClick={maxPage - curPage > 0 ? next : undefined}
        isActive={maxPage - curPage > 0}
      >
        〉
      </Button>
    </Container>
  );
};

const Container = styled.div`
  ${({ theme }) => theme.flexSet()}
`;

const Button = styled.button<{ isActive?: boolean }>`
  width: 40px;
  height: 40px;
  margin: 10px;
  border-radius: 100%;
  color: white;
  font-weight: 900;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary100 : theme.colors.gray200};
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
`;

export default PageButtons;
