// 상세 내역들에서 사용 중인 간략한 유저정보를 보여주는 박스 컴포넌트
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import styled from "styled-components";
import { API } from "../config";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { User } from "../interfaces/Interfaces";
import { Gender } from "../styles/theme";
import Profile from "./Profile";
import UserStateBadge from "./UserStateBadge";

interface propTypes {
  // 유저 pk
  id: number;
  // 컴포넌트 외부 클릭 시 실행되는 콜백 함수
  onClose: Function;
}

const UserDetail = ({ id, onClose }: propTypes) => {
  const [user, setUser] = useState<User>();
  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`${API.USER_D}/${id}`).then((res) => {
      setUser(res.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    onClose();
  });

  return user ? (
    <Container
      ref={ref}
      onClick={() => {
        navigate({
          pathname: "/dashboard/user",
          search: `?${createSearchParams({ phone: user.phone })}`,
        });
      }}
    >
      <Profile size={100} src={user.profile} />
      <Nickname>
        {user.nickname}
        <Gender gender={user.gender ?? 0} noMargin>
          {user.gender === 1 ? "M" : "F"}
        </Gender>
      </Nickname>
      <p>{user.phone}</p>
      <p>{user.date_of_birth}</p>
      <UserStateBadge state={user.user_state} />
    </Container>
  ) : null;
};

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.orange};
  color: white;
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.3;
  z-index: 999;
`;

const Nickname = styled.div`
  ${({ theme }) => theme.flexSet()}
  margin-top: 15px;
  font-size: 1rem;
`;

export default UserDetail;
