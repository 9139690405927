import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import CustomTable from '../../components/CustomTable';
import PageButtons from '../../components/PageButtons';
import { API } from '../../config';
import { Head, Report } from '../../interfaces/Interfaces';
import { optionState } from '../../recoil/atom';
import parseReport from '../../utils/parseReport';
import parseReportType from '../../utils/parseReportType';
import ReportStateBadge from './components/ReportStateBadge';

const ReportList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const options = useRecoilValue(optionState);
  const [reports, setReports] = useState<Report[]>([]);
  const [pageInfo, setPageInfo] = useState({
    current_page: parseInt(location.search.split('=')[1]),
    total_items: 0,
    total_pages: 0,
  });
  const offset = useRef<Number>(6);

  const getReportList = async (page: number) => {
    await axios({
      url: API.REPORT,
      params: {
        page,
        offset: offset.current,
        ...options,
      },
    }).then((res) => {
      const data = res.data.data;
      setReports(data.items);
      setPageInfo({
        current_page: data.current_page,
        total_items: data.total_items,
        total_pages: data.total_pages,
      });
    });
  };

  const goToPrev = () => {
    navigate({
      search: `?${createSearchParams({
        page: `${pageInfo.current_page - 1}`,
      })}`,
    });
  };

  const goToNext = () => {
    navigate({
      search: `?${createSearchParams({
        page: `${pageInfo.current_page + 1}`,
      })}`,
    });
  };

  useEffect(() => {
    getReportList(parseInt(location.search.split('=')[1]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, Object.entries(options).join()]);

  return (
    <Container>
      <CustomTable
        title="전체 신고"
        totalItems={pageInfo.total_items}
        heads={HEAD_INFO}
        data={reports.map((report) => {
          const { report_type, info } = report;
          return {
            onClick: () =>
              navigate(`${info.report_id}`, {
                state: report_type,
              }),
            data: [
              info.report_id,
              <ReportStateBadge state={info.status} />,
              parseReportType(info.reason_type),
              parseReport(report_type),
              info.reason_detail,
              info.reporting_user.nickname,
              info.reported_user.nickname,
              info.created_date,
            ],
          };
        })}
      />

      <PageButtons
        curPage={pageInfo.current_page}
        maxPage={pageInfo.total_pages}
        prev={goToPrev}
        next={goToNext}
      />
    </Container>
  );
};

const Container = styled.div``;

export default ReportList;

const HEAD_INFO: Array<Head> = [
  {
    id: 0,
    title: 'report_pk',
    options: [{ id: 0, label: 'ID' }],
  },
  {
    id: 1,
    title: 'status',
    type: 'filter',
    options: [
      {
        id: 0,
        label: '처리상태',
      },
      { id: 1, label: '전체 처리상태' },
      { id: 2, label: '대기중' },
      { id: 3, label: '기각' },
      { id: 4, label: '승인' },
    ],
  },
  {
    id: 2,
    title: 'report_reason',
    type: 'filter',
    options: [
      {
        id: 0,
        label: '신고사유',
      },
      { id: 1, label: '전체 신고사유' },
      { id: 2, label: '개인정보 요구' },
      { id: 3, label: '폭력적/외설적 발언' },
      { id: 4, label: '불법 및 허위정보 유포' },
      { id: 5, label: '청소년 유해 및 음란' },
      { id: 6, label: '홍보 및 영리 목적' },
      { id: 7, label: '기타' },
    ],
  },
  {
    id: 7,
    title: 'report_type',
    type: 'filter',
    options: [
      {
        id: 0,
        label: '신고 분류',
      },
      { id: 1, label: '전체 신고' },
      { id: 2, label: '회원 신고' },
      { id: 3, label: '게시글 신고' },
      { id: 4, label: '댓글 신고' },
    ],
  },
  {
    id: 3,
    title: 'report_detail',
    options: [{ id: 0, label: '상세내용' }],
  },
  {
    id: 4,
    title: 'reporting_user',
    options: [{ id: 0, label: '신고자' }],
  },
  {
    id: 5,
    title: 'reported_user',
    options: [{ id: 0, label: '피신고자' }],
  },
  {
    id: 6,
    title: 'created_date',
    type: 'sort',
    options: [
      { id: 0, label: '신고 접수일' },
      { id: 1, label: '신고 접수일 ↑' },
      { id: 2, label: '신고 접수일 ↓' },
    ],
  },
];
