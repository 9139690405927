// 회원상태 변경 메뉴 버튼 (편집 가능한 경우에 활성화)
// 편집버튼 클릭 후 상태 badge 클릭 시, 상태 변경 버튼 모달이 나타남
import { useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import styled from 'styled-components/macro';
import Button from '../../../components/Button';
import UserStateBadge from '../../../components/UserStateBadge';
import { selectedAccountState } from '../../../recoil/atom';
interface propsType {
  userId: number;
  state: number;
  isActive: boolean;
}

const AccountStateMenu = ({ state, isActive }: propsType) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedState, setSeletedState] = useRecoilState(selectedAccountState);
  const resetAccountState = useResetRecoilState(selectedAccountState);

  const handleModalShow = () => {
    isActive && setIsOpen((prev) => !prev);
  };

  const showPrompt = (value: string) => {
    switch (value) {
      case '1':
        return window.confirm('해당 계정을 활성화하시겠습니까?');
      case '3':
        return window.prompt(
          '해당 계정은 3일 정지하겠습니까?',
          '신고 사유를 입력해주세요'
        );
      case '4':
        return window.prompt(
          '해당 계정은 영구 정지하겠습니까?',
          '신고 사유를 입력해주세요'
        );
    }
  };

  const handleMenuClick = async (e: React.FocusEvent) => {
    const value = e.relatedTarget?.getAttribute('value');
    if (value === '1') {
      const isReactivated = showPrompt(value);
      isReactivated
        ? setSeletedState({ ban_type: parseInt(value) })
        : resetAccountState();
    }
    if (value === '3' || value === '4') {
      const reason = showPrompt(value);
      reason
        ? setSeletedState({
            ban_type: parseInt(value),
            message: reason.toString(),
          })
        : resetAccountState();
    }
  };

  return (
    <Wrapper
      onClick={handleModalShow}
      onBlur={(e) => {
        handleMenuClick(e);
        handleModalShow();
      }}
    >
      <Button
        fullWidth
        disabled={!isActive}
        color={isActive ? 'primary100' : undefined}
      >
        <UserStateBadge
          state={
            isActive && !!selectedState.ban_type
              ? selectedState.ban_type
              : state
          }
        />
      </Button>
      {isOpen && (
        <Menu>
          <MenuItem value="1" color="gray200">
            활성
          </MenuItem>
          <MenuItem value="3" color="gray200" onClick={() => {}}>
            3일정지
          </MenuItem>
          <MenuItem value="4" color="gray200">
            영구정지
          </MenuItem>
        </Menu>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Menu = styled.div`
  ${({ theme }) => theme.flexSet()};
  gap: 0.3rem;
  padding: 1rem;
  position: absolute;
  top: -200%;
  left: -100%;
  z-index: 1;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
`;

const MenuItem = styled(Button)`
  word-break: keep-all;
  font-size: 0.8rem;
`;

export default AccountStateMenu;
