const parseState = (state) => {
  switch (state) {
    case 'PENDING':
      return { text: '대기중', color: 'pending' };
    case 'REJECT':
      return { text: '반려', color: 'reject' };
    case 'CONFIRM':
      return { text: '처리완료', color: 'done' };
    default:
  }
  return null;
};

export default parseState;
