// 로더
import styled from 'styled-components';

const Loading = () => {
  return <Container>⌛️</Container>;
};

const Container = styled.div`
  ${({ theme }) => theme.posCenter('absolute')};
  ${({ theme }) => theme.flexSet()}
  font-size: 2rem;
  animation: rotate 2s infinite linear;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default Loading;
