const parseReportType = (type) => {
  switch (type) {
    case 1:
      return '개인정보 요구';
    case 2:
      return '폭력적/외설적 발언';
    case 3:
      return '불법 및 허위정보 유포';
    case 4:
      return '청소년 유해 및 음란';
    case 5:
      return '홍보 및 영리 목적';
    case 6:
      return '기타';
    default:
  }
  return null;
};

export default parseReportType;
