// 회원정보 수정 버튼
import styled from 'styled-components';

interface propTypes {
  editable: boolean;
  onClick: Function;
}

const EditButton = ({ editable, onClick }: propTypes) => {
  return (
    <Button
      onClick={() => {
        onClick();
      }}
    >
      {editable ? '🔐' : '🔓'}
    </Button>
  );
};

const Button = styled.button`
  color: ${({ theme }) => theme.orange};
  font-weight: 800;
  font-size: 1.2rem;
`;

export default EditButton;
