// 관리자 전체에서 사용중인 테이블 컴포넌트
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useResetRecoilState, useRecoilState } from "recoil";
import styled, { css } from "styled-components";
import { Head } from "../interfaces/Interfaces";
import { optionState } from "../recoil/atom";
import { Highlight } from "../styles/theme";
import Loading from "./Loading";

interface propsType {
  // 표에 사용될 타이틀 정보를 담은 array
  heads: Array<Head>;
  // 표에 사용될 컨텐츠 정보를 담은 array
  data: Array<Array<any>>;
  // 표의 전체 제목
  title?: string;
  // 표의 전체 데이터 수
  totalItems?: number;
  // 하나의 row를 클릭 시 실행되는 콜백함수
  toDetail?: Function;
  // 전체 데이터 수 : 404 확인 목적
  total?: number;
}
const Table = ({
  heads,
  data,
  toDetail,
  title,
  totalItems,
  total,
}: propsType): JSX.Element => {
  const location = useLocation();
  const [width, setWidth] = useState<number>();
  const [activatedOption, setActivatedOpion] = useState("");
  const [_selected, setSelected] = useRecoilState(optionState);
  const resetOptions = useResetRecoilState(optionState);
  const type = location.pathname.split("/").pop();

  const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setActivatedOpion(name);
    setSelected({ [name]: Number(value) });
  };

  useEffect(() => {
    // 탭이 바뀌면 선택 값 초기화
    resetOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (document.getElementById("table")) {
      setWidth(document.getElementById("table")?.offsetWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  return (
    <Wrapper width={width}>
      <Container>
        {title && (
          <Label>
            {title} <Highlight>{totalItems}</Highlight>
          </Label>
        )}
        <TableWrapper id="table">
          <thead>
            <tr>
              {heads.map((el) => (
                <TableHead key={el.id}>
                  {el.options.length > 1 ? (
                    <SelectBox
                      name={el.title}
                      required
                      itemType={el.type}
                      onChange={onChangeHandler}
                      isActive={el.title === activatedOption}
                      defaultValue=""
                    >
                      {el.options.map((el) =>
                        el.id === 0 ? (
                          <option key={el.id} value="" disabled>
                            {el.label}
                          </option>
                        ) : (
                          <option key={el.id} value={el.id}>
                            {el.label}
                          </option>
                        )
                      )}
                    </SelectBox>
                  ) : (
                    el.options[0].label
                  )}
                </TableHead>
              ))}
            </tr>
          </thead>
          <tbody>
            {totalItems === 0 ? (
              <Empty>
                <Loading />
              </Empty>
            ) : (
              data.map((whole, i) => (
                <TableRow
                  key={i}
                  onClick={() => (toDetail ? toDetail(whole[0]) : null)}
                >
                  {whole.map((detail, idx) => (
                    <TableData key={`${i}${idx}`}>{detail}</TableData>
                  ))}
                </TableRow>
              ))
            )}
          </tbody>
        </TableWrapper>
      </Container>
    </Wrapper>
  );
};

const SelectBox = styled.select<{ isActive: boolean }>`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ isActive }) => (isActive ? "black" : "grey")};
  border: none;

  & > option[value=""][disabled] {
    display: none;
  }
`;

const Empty = styled.tr`
  height: 200px;
  position: relative;
`;

const Wrapper = styled.div<{ width?: number }>`
  width: 100vw;
  overflow: scroll;
  ${({ width }) => {
    return css`
      @media screen and (min-width: ${width}px) {
        width: fit-content;
      }
    `;
  }};
`;

const Container = styled.div`
  width: 100%;
  padding: 1rem;
  position: relative;

  @media ${({ theme }) => theme.mobile} {
    min-width: 90vw;
  }
`;

const Label = styled.h2`
  margin: 20px;
  font-size: 1.35rem;
  font-weight: 800;
`;

const TableWrapper = styled.table`
  width: max-content;
  min-width: 600px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 0px 6px ${({ theme }) => theme.lightGray};

  @media ${({ theme }) => theme.mobile} {
    min-width: 100%;
  }
`;

const TableHead = styled.th`
  padding: 10px 15px;
  text-align: center;
  font-weight: 700;
  color: gray;

  @media ${({ theme }) => theme.mobile} {
    padding: 10px;
  }
`;

const TableRow = styled.tr`
  border-top: 1px solid ${(props) => props.theme.lightGray};
`;

const TableData = styled.td`
  padding: 5px 15px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  @media ${({ theme }) => theme.mobile} {
    padding: 5px 10px;
  }
`;

export default Table;
