import { Navigate, useRoutes } from "react-router-dom";
import Layout from "./components/Layout";
import InquiryDetail from "./pages/inquiry/InquiryDetail";
import InquiryList from "./pages/inquiry/InquiryList";
import Main from "./pages/main/Main";
import PostDetail from "./pages/post/PostDetail";
import PostList from "./pages/post/PostList";
import ReportList from "./pages/report";
import ReportDetail from "./pages/report/report";
import Signin from "./pages/signin";
import UserList from "./pages/user/UserList";

const Routes = () => {
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: <Layout />,
      children: [
        {
          path: "/dashboard",
          element: <Navigate to="/dashboard/summary" replace />,
        },

        { path: "summary", element: <Main /> },
        { path: "user", element: <UserList /> },
        { path: "post", element: <PostList /> },
        { path: "post/:id", element: <PostDetail /> },
        { path: "inquiry", element: <InquiryList /> },
        { path: "inquiry/:id", element: <InquiryDetail /> },
        { path: "report", element: <ReportList /> },
        { path: "report/:id", element: <ReportDetail /> },
      ],
    },
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "login", element: <Signin /> },
        { path: "/", element: <Navigate to="/dashboard" /> },
        {
          path: "/user",
          element: <Navigate to="/dashboard/user?page=1" replace />,
        },
        {
          path: "/post",
          element: <Navigate to="/dashboard/post?page=1" replace />,
        },
        {
          path: "/report",
          element: <Navigate to="/dashboard/report?page=1" replace />,
        },
        {
          path: "/inquiry",
          element: <Navigate to="/dashboard/inquiry?page=1" replace />,
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);

  return routes;
};

export default Routes;
