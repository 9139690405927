import axios from 'axios';
import { useEffect, useState } from 'react';
import Table from '../../../components/Table';
import { API } from '../../../config';
import { Head, Report } from '../../../interfaces/Interfaces';
import parseReport from '../../../utils/parseReport';

interface propTypes {
  count: number;
}

type PageInfoType = {
  currentPage: number;
  totalItems: number;
  totalPage: number;
};

const ReportPreview = ({ count }: propTypes) => {
  const [reports, setReports] = useState<Report[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfoType>();

  useEffect(() => {
    axios.get(`${API.REPORT}?page=1`).then((res) => {
      setReports(res.data.data.items.slice(0, 5));
      setPageInfo({
        currentPage: res.data.data.current_page,
        totalItems: res.data.data.total_items,
        totalPage: res.data.data.total_pages,
      });
    });
  }, []);

  return (
    <Table
      total={pageInfo?.totalItems}
      title="🚨 처리되지 않은 신고"
      heads={HEAD_INFO}
      data={reports.map((report) => {
        const { info, report_type } = report;
        return [
          parseReport(report_type),
          info.reason_detail,
          info.created_date,
        ];
      })}
      totalItems={count}
    />
  );
};

export default ReportPreview;

const HEAD_INFO: Array<Head> = [
  {
    id: 0,
    title: 'report_type',
    options: [{ id: 0, label: '신고 유형' }],
  },
  {
    id: 1,
    title: 'report_reason',
    options: [{ id: 0, label: '신고 사유' }],
  },
  {
    id: 2,
    title: 'created_date',
    options: [{ id: 0, label: '날짜' }],
  },
];
