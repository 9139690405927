// 전체 회원 내역
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import { createSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import CountButton from "../../components/CountButton";
import PageButtons from "../../components/PageButtons";
import Profile from "../../components/Profile";
import Table from "../../components/Table";
import { API } from "../../config";
import { Head, User } from "../../interfaces/Interfaces";
import { optionState, selectedAccountState } from "../../recoil/atom";
import { Gender } from "../../styles/theme";
import AccountStateMenu from "./components/AccountStateMenu";
import EditButton from "./components/EditButton";
import SearchBar from "./components/SearchBar";

const UserList = (): JSX.Element => {
  const [users, setUsers] = useState<User[]>([]);
  const [count, setCount] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const curPage = parseInt(location.search.split("=")[1]);
  const [key, value] = location.search.split("=");
  const [idToEdit, setIdToEdit] = useState<number>();
  const editedAccountInfo = useRecoilValue(selectedAccountState);
  const options = useRecoilValue(optionState);
  const valueRef = useRef<Array<HTMLInputElement | null>>([]);
  // console.log(Object.entries(options).join());
  const getUserList = () => {
    const url =
      key === "?page"
        ? `${API.USER_LIST}?page=${value}`
        : `${API.USER}?search=${value}`;
    axios.get(url, { params: { ...options } }).then((res) => {
      setCount(res.data.entire);
      setUsers(res.data.data);
      setMaxPage(res.data.max_page);
    });
  };

  const toggleEditState = (id: number) => {
    switch (typeof idToEdit) {
      case "undefined":
        setIdToEdit(id);
        break;
      case "number":
        idToEdit !== id ? setIdToEdit(id) : showEditConfirm();
        break;
    }
  };

  const showEditConfirm = () => {
    window.confirm("회원정보를 수정하시겠습니까?")
      ? editUserInfo()
      : setIdToEdit(undefined);
  };

  const editUserInfo = async () => {
    const data = {
      user_id: idToEdit,
      date_of_birth: valueRef.current[0]?.value,
      phone: valueRef.current[1]?.value,
      temperature: valueRef.current[2]?.value,
      ...editedAccountInfo,
    };

    const res = await axios.put(API.USER_LIST, data);
    if (res.status === 200) {
      window.alert("회원정보가 변경되었습니다.");
      window.location.reload();
    } else {
      window.alert(`오류가 발생했습니다. status: ${res.status}`);
    }
  };

  useEffect(() => {
    getUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, Object.entries(options).join()]);

  return (
    <Container>
      <SearchBar />
      <Table
        title="전체 회원"
        heads={HEAD_INFO}
        data={users.map((user) => [
          user.id,
          <Profile size={30} src={user.profile} />,
          user.nickname,
          <Gender gender={user.gender}>{user.gender === 1 ? "M" : "F"}</Gender>,
          idToEdit === user.id ? (
            <Input
              defaultValue={user.date_of_birth}
              ref={(el) => {
                valueRef.current[0] = el;
              }}
            />
          ) : (
            user.date_of_birth
          ),
          user.date_joined,
          idToEdit === user.id ? (
            <Input
              defaultValue={user.phone}
              ref={(el) => {
                if (idToEdit === user.id) valueRef.current[1] = el;
              }}
            />
          ) : (
            user.phone
          ),
          <CountButton uid={user.id} count={user.report_count} />,
          <CountButton uid={user.id} count={user.reported_count} />,
          <CountButton
            uid={user.id}
            count={user.block_user.length}
            detail={user.block_user}
          />,
          <CountButton
            uid={user.id}
            count={user.blocked_user.length}
            detail={user.blocked_user}
          />,
          idToEdit === user.id ? (
            <Input
              defaultValue={user.temperature}
              ref={(el) => {
                if (idToEdit === user.id) valueRef.current[2] = el;
              }}
            />
          ) : (
            user.temperature
          ),
          <CountButton
            count={user.interest.length}
            uid={user.id}
            detail={user.interest}
          />,
          <AccountStateMenu
            userId={user.id}
            state={user.user_state}
            isActive={idToEdit === user.id}
          />,
          <EditButton
            editable={idToEdit === user.id}
            onClick={() => toggleEditState(user.id)}
          />,
        ])}
        totalItems={count}
      />
      {key === "?page" && (
        <PageButtons
          curPage={curPage}
          maxPage={maxPage}
          prev={() => {
            if (curPage > 1) {
              navigate({
                search: `?${createSearchParams({ page: `${curPage - 1}` })}`,
              });
            }
          }}
          next={() => {
            if (curPage < maxPage) {
              navigate({
                search: `?${createSearchParams({ page: `${curPage + 1}` })}`,
              });
            }
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 1300px;
  max-width: 100vw;
  width: 100%;
  ${({ theme }) => theme.flexColumnSet()};
  padding: 1rem;
`;

const Input = styled.input<{
  defaultValue: string;
}>`
  width: ${(props) => props.defaultValue.length * 10}px;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.orange};
  font-size: 1rem;
`;

export default UserList;

const HEAD_INFO: Array<Head> = [
  {
    id: 0,
    title: "user_pk",
    options: [{ id: 0, label: "ID" }],
  },
  {
    id: 1,
    title: "profile",
    options: [{ id: 0, label: "프로필" }],
  },
  {
    id: 2,
    title: "username",
    options: [{ id: 0, label: "닉네임" }],
  },
  {
    id: 3,
    title: "gender",
    options: [{ id: 0, label: "성별" }],
  },
  {
    id: 4,
    title: "birth",
    options: [{ id: 0, label: "생년월일" }],
  },
  {
    id: 5,
    title: "created_date",
    type: "sort",
    options: [
      { id: 0, label: "가입일" },
      { id: 1, label: "최신순" },
      { id: 2, label: "가입순" },
    ],
  },
  {
    id: 6,
    title: "phone",
    options: [{ id: 0, label: "휴대폰" }],
  },
  {
    id: 7,
    title: "reporting",
    type: "sort",
    options: [
      { id: 0, label: "신고한" },
      { id: 1, label: "신고한 ↑" },
      { id: 2, label: "신고한 ↓" },
    ],
  },
  {
    id: 8,
    title: "reported",
    type: "sort",
    options: [
      { id: 0, label: "신고당한" },
      { id: 1, label: "신고당한 ↑" },
      { id: 2, label: "신고당한 ↓" },
    ],
  },
  {
    id: 9,
    title: "blocking",
    type: "sort",
    options: [
      { id: 0, label: "차단한" },
      { id: 1, label: "차단한 ↑" },
      { id: 2, label: "차단한 ↓" },
    ],
  },
  {
    id: 10,
    title: "blocked",
    type: "sort",
    options: [
      { id: 0, label: "차단당한" },
      { id: 1, label: "차단당한 ↑" },
      { id: 2, label: "차단당한 ↓" },
    ],
  },
  {
    id: 11,
    title: "temperature",
    type: "sort",
    options: [
      { id: 0, label: "온도" },
      { id: 1, label: "온도 ↑" },
      { id: 2, label: "온도 ↓" },
    ],
  },
  {
    id: 12,
    title: "interest",
    options: [{ id: 0, label: "관심사" }],
  },
  {
    id: 13,
    title: "status",
    type: "filter",
    options: [
      { id: 0, label: "상태" },
      { id: 1, label: "전체" },
      { id: 2, label: "활성" },
      { id: 3, label: "3일정지" },
      { id: 4, label: "영구정지" },
      { id: 5, label: "탈퇴" },
    ],
  },
  {
    id: 14,
    title: "edit",
    options: [{ id: 0, label: "편집" }],
  },
];
