// 전체 게시물 페이지
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import PageButtons from "../../components/PageButtons";
import Table from "../../components/Table";
import { API } from "../../config";
import { Head, Post } from "../../interfaces/Interfaces";
import { optionState } from "../../recoil/atom";
import { Gender } from "../../styles/theme";

const PostList = () => {
  const options = useRecoilValue(optionState);
  const [posts, setPosts] = useState<Post[]>([]);
  const [count, setCount] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const curPage = parseInt(location.search.split("=")[1]);

  useEffect(() => {
    // 페이지 또는 옵션이 바뀔 때 다시 데이터 요청
    getPostList(curPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, Object.entries(options).join()]);

  const getPostList = (page: number) => {
    axios.get(API.POST, { params: { page, ...options } }).then((res) => {
      setCount(res.data.data.page_info.total_items);
      setPosts(res.data.data.list);
      setMaxPage(res.data.data.page_info.total_page);
    });
  };

  return (
    <Container>
      <Table
        title="전체 게시글"
        heads={HEAD_INFO}
        data={posts.map((post) => [
          post.feed_id,
          <ImgWrapper>
            <img alt="게시글 이미지" src={post.images[0]} />
          </ImgWrapper>,
          <TextWrapper>{post.text}...</TextWrapper>,
          <TagWrapper>
            {post.tags.map((tag) => (
              <Tag>{tag}</Tag>
            ))}
          </TagWrapper>,
          post.created_date.substring(2, 10),
          post.author.nickname,
          post.count_info.comments,
          post.count_info.likes,
          post.is_deleted ? (
            <Gender gender={2}>삭제됨</Gender>
          ) : (
            <Gender gender={1}>등록</Gender>
          ),
        ])}
        toDetail={(id: number) => {
          navigate(`${id}`);
        }}
        totalItems={count}
      />
      <PageButtons
        curPage={curPage}
        maxPage={maxPage}
        prev={() => {
          if (curPage > 1) {
            navigate({
              search: `?${createSearchParams({ page: `${curPage - 1}` })}`,
            });
          }
        }}
        next={() => {
          if (curPage < maxPage) {
            navigate({
              search: `?${createSearchParams({ page: `${curPage + 1}` })}`,
            });
          }
        }}
      />
    </Container>
  );
};

const Container = styled.div``;

const ImgWrapper = styled.div`
  width: 133px;
  height: 100px;
  overflow: hidden;

  img {
    width: 100%;
    transform: translateY(-30%);
  }
`;

const TextWrapper = styled.div`
  width: 400px;
  text-align: left;

  @media ${({ theme }) => theme.mobile} {
    width: 300px;
  }
`;

const TagWrapper = styled.div`
  width: 150px;
  text-align: left;

  @media ${({ theme }) => theme.mobile} {
    width: 100px;
  }
`;

const Tag = styled.p`
  display: inline-block;
  width: fit-content;
  margin: 2px;
  padding: 2px 8px;
  border-radius: 10px;
  background-color: orange;
  color: white;
`;

export default PostList;

const HEAD_INFO: Array<Head> = [
  {
    id: 0,
    title: "feed_pk",
    options: [{ id: 0, label: "ID" }],
  },
  {
    id: 1,
    title: "photo",
    options: [{ id: 0, label: "사진" }],
  },
  {
    id: 2,
    title: "content",
    options: [{ id: 0, label: "내용" }],
  },
  {
    id: 3,
    title: "tag",
    options: [{ id: 0, label: "태그" }],
  },
  {
    id: 4,
    title: "created_date",
    type: "sort",
    options: [
      { id: 0, label: "날짜" },
      { id: 1, label: "날짜 ↑" },
      { id: 2, label: "날짜 ↓" },
    ],
  },
  {
    id: 5,
    title: "username",
    options: [{ id: 0, label: "닉네임" }],
  },
  {
    id: 6,
    title: "comments",
    type: "sort",
    options: [
      { id: 0, label: "댓글 수" },
      { id: 1, label: "댓글 ↑" },
      { id: 2, label: "댓글 ↓" },
    ],
  },
  {
    id: 7,
    title: "likes",
    type: "sort",
    options: [
      { id: 0, label: "좋아요 수" },
      { id: 1, label: "좋아요 ↑" },
      { id: 2, label: "좋아요 ↓" },
    ],
  },
  {
    id: 8,
    title: "status",
    type: "filter",
    options: [
      { id: 0, label: "상태" },
      { id: 1, label: "전체" },
      { id: 2, label: "등록" },
      { id: 3, label: "삭제" },
    ],
  },
];
