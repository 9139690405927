// 관리자 네브바
import { useLocation } from 'react-router';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Header = (): JSX.Element => {
  const isLogin = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const location = useLocation();

  const goToLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <Container>
      <Wrapper isLogin={!!isLogin}>
        <Logo to="/">
          <img alt="wena logo" src="/images/wena-white.svg" />
        </Logo>
        {isLogin && (
          <Menu>
            <Tab
              active={location.pathname === '/dashboard/summary'}
              onClick={() => {
                navigate('/');
              }}
            >
              📊
              <Space />
              요약
            </Tab>
            <Tab
              active={location.pathname.includes('/dashboard/user')}
              onClick={() => {
                navigate({
                  pathname: '/user',
                  search: `?${createSearchParams({ page: '1' })}`,
                });
              }}
            >
              👨‍👩‍👧‍👦
              <Space />
              유저
            </Tab>
            <Tab
              active={location.pathname.includes('/dashboard/post')}
              onClick={() => {
                navigate({
                  pathname: '/post',
                  search: `?${createSearchParams({ page: '1' })}`,
                });
              }}
            >
              🔖
              <Space />
              게시글
            </Tab>
            <Tab
              active={location.pathname.includes('/dashboard/report')}
              onClick={() => {
                navigate({
                  pathname: '/report',
                  search: `?${createSearchParams({ page: '1' })}`,
                });
              }}
            >
              🚨
              <Space />
              신고
            </Tab>
            <Tab
              active={location.pathname.includes('/dashboard/inquiry')}
              onClick={() => {
                navigate({
                  pathname: '/inquiry',
                  search: `?${createSearchParams({ page: '1' })}`,
                });
              }}
            >
              💬
              <Space />
              문의
            </Tab>
          </Menu>
        )}
        <LoginBtn
          className="login"
          onClick={isLogin ? handleLogout : goToLogin}
        >
          🖐
          <Space />
          {isLogin ? '로그아웃' : '로그인'}
        </LoginBtn>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  ${({ theme }) => theme.flexSet()};
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.orange};
  z-index: 99;
  border: none;
`;

const Wrapper = styled.div<{ isLogin?: boolean }>`
  ${({ theme }) => theme.flexSet('flex-end', 'flex-end')}
  flex-wrap:wrap;
  padding: 0 3%;
  width: 1400px;
`;

const Menu = styled.div`
  order: 2;
  @media ${({ theme }) => theme.mobile} {
    width: 100%;
    order: 3;
  }
`;

const Logo = styled(Link)`
  width: 120px;
  padding: 15px;
  margin-right: auto;
  @media ${({ theme }) => theme.mobile} {
    width: 18vw;
  }
  order: 1;
`;

const Tab = styled.button<{ active?: boolean }>`
  width: 120px;
  padding: 8px;
  border-radius: 10px 10px 0 0;
  background-color: ${(props) => (props.active ? 'white' : null)};
  color: ${(props) => (props.active ? props.theme.orange : 'white')};
  font-size: 1rem;
  font-weight: 900;
  cursor: pointer;
  border: none;
  @media ${({ theme }) => theme.mobile} {
    width: 20%;
    font-size: 3vw;
  }
`;

const LoginBtn = styled(Tab)`
  order: 3;
  @media screen and (max-width: 893px) {
    order: 1;
    width: fit-content;
  }
`;

const Space = styled.div`
  display: inline-block;
  width: 12px;
`;

export default Header;
