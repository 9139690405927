// 유저 정보 검색 바 : 핸드폰 번호로 검색 가능
import styled from 'styled-components';

const SearchBar = () => {
  return (
    <Container>
      <Form>
        <Input name="phone" placeholder="휴대폰 번호로 검색" />
        <button type="submit">🔍</button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  width: 400px;
  margin: 10px;
  padding: 7px 20px;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.orange};

  @media ${({ theme }) => theme.mobile} {
    width: 94vw;
    margin: auto;
  }
`;

const Form = styled.form`
  ${({ theme }) => theme.flexSet('space-between')}
`;

const Input = styled.input`
  width: 85%;
  font-size: 1rem;
`;
export default SearchBar;
