import { GlobalStyle } from './styles/globalStyles';
import { ThemeProvider } from 'styled-components';
import { RecoilRoot } from 'recoil';
import Routes from './Routes';
import theme from './styles/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <GlobalStyle />
        <Routes />
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;
