// 신고 상세페이지
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router';
import styled from 'styled-components/macro';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import Profile from '../../components/Profile';
import UserDetail from '../../components/UserDetail';
import { API } from '../../config';
import { Report } from '../../interfaces/Interfaces';
import parseReport from '../../utils/parseReport';
import parseReportType from '../../utils/parseReportType';
import ReportStateBadge from './components/ReportStateBadge';

const ReportDetail = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [showDetail, setShowDetail] = useState([false, false]);
  const [report, setReport] = useState<Report>();

  // 기각 또는 승인 버튼 클릭 시 실행되는 함수
  // confirm창이 true일때 confirmReport() 실행
  const handleButtonClick = async (val: string) => {
    if (
      window.confirm(
        `신고를 ${val === 'REJECT' ? '기각' : '승인'}하시겠습니까?`
      )
    ) {
      await confirmReport(val);
    }
  };

  // 신고 승인 또는 기각 처리 함수
  // status에 원하는 상태값을 넣어 전송
  const confirmReport = async (status: string) => {
    const { data: response } = await axios.post(API.REPORT_CONFIRM, {
      report_id: id,
      status,
    });
    return response.data;
  };

  useEffect(() => {
    axios(`${API.REPORT_D}/${id}`).then((res) => {
      setReport(res.data.data);
    });
  }, [id]);

  return report ? (
    <Container>
      <ShadowBox>
        <ReportType>
          <p>{parseReport(report.info.reason_type)}</p>
          <p>{parseReportType(report.info.reason_type)}</p>
          <ReportStateBadge state={report.info.status} />
        </ReportType>
        <AccountInfo>
          <Account>
            {showDetail[0] && (
              <UserDetail
                id={report.info.reporting_user.user_id}
                onClose={() => setShowDetail((prev) => [false, prev[1]])}
              />
            )}
            <span>신고자 : </span>
            <ProfileCard
              color="done"
              onClick={() => setShowDetail((prev) => [true, prev[1]])}
            >
              <div>{report.info.reporting_user?.nickname}</div>
              <Profile size={20} />
              <div>{report.info.reporting_user?.phone}</div>
            </ProfileCard>
          </Account>
          <Account>
            {showDetail[1] && (
              <UserDetail
                id={report.info.reported_user.user_id}
                onClose={() => setShowDetail((prev) => [prev[0], false])}
              />
            )}
            <span>피신고자 : </span>
            <ProfileCard
              color="done"
              onClick={() => setShowDetail((prev) => [prev[0], true])}
            >
              <div>{report.info.reported_user?.nickname}</div>
              <Profile size={20} />
              <div>{report.info.reported_user?.phone}</div>
            </ProfileCard>
          </Account>
        </AccountInfo>
        <Contents>
          {/* 신고 타입에 따라 컨텐츠에 맞게 렌더됨 : 총 3가지 타입으로, user, comment, feed가 있음  */}
          {state === 'user' && (
            <div>
              <Title>신고 상세 사유 : </Title>
              <Content>{report.info.reason_detail}</Content>
            </div>
          )}
          {state === 'comment' && (
            <div>
              <Title>신고 상세 사유 : </Title>
              <Content>{report.info.reason_detail}</Content>
              <Title>신고 댓글 내용: </Title>
              <Content>{report.info.comment?.text}</Content>
            </div>
          )}
          {state === 'feed' && (
            <div>
              <Title>신고 상세 사유 : </Title>
              <Content>{report.info.reason_detail}</Content>
              <Title>신고된 게시글 내용 : </Title>
              <Content>{report.info.feed?.text}</Content>
              <ImageWrpper>
                {report.info.feed?.image?.map((el) => (
                  <img src={el} alt="feed images" />
                ))}
              </ImageWrpper>
            </div>
          )}
        </Contents>
        <BtnGroup>
          <ReportButton
            color="gray200"
            value="REJECT"
            onClick={(e) => {
              e.stopPropagation();
              handleButtonClick(e.currentTarget.getAttribute('value')!);
            }}
          >
            기각
          </ReportButton>
          <ReportButton
            color="primary200"
            value="CONFIRM"
            onClick={(e) => {
              e.stopPropagation();
              handleButtonClick(e.currentTarget.getAttribute('value')!);
            }}
          >
            승인
          </ReportButton>
        </BtnGroup>
      </ShadowBox>
    </Container>
  ) : (
    <Loading />
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 10px;
`;

const AccountInfo = styled.div`
  ${({ theme }) => theme.flexSet('flex-start')};
  gap: 0.4rem;
`;

const Account = styled.div`
  position: relative;
  ${({ theme }) => theme.flexSet()};
  gap: 0.4rem;
`;

const ProfileCard = styled(Button)`
  ${({ theme }) => theme.flexSet()};
  gap: 0.2rem;
  border: none;
  padding: 0.4rem;
  border-radius: 10px;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.black};
`;

const BtnGroup = styled.div`
  ${({ theme }) => theme.flexSet('flex-end')};
  gap: 0.4rem;
`;

const ShadowBox = styled.div`
  width: 100%;
  position: relative;
  padding: 25px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 0px 6px lightgray;

  @media ${({ theme }) => theme.mobile} {
    padding: 10px;
  }
`;

const ReportType = styled.div`
  ${({ theme }) => theme.flexSet('flex-start')}
  gap : 1rem;
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 1.2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray100}; ;
`;

const Contents = styled.div`
  margin: 2rem 0;
  padding: 1rem 0.6rem;
`;

const ReportButton = styled(Button)`
  @media ${({ theme }) => theme.mobile} {
    width: 100%;
    justify-content: center;
  }
`;

const Title = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6;
`;

const Content = styled.p`
  padding: 0.4rem;
  font-size: 1rem;
  line-height: 1.4;
`;

const ImageWrpper = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > img {
    width: 20%;
  }
`;

export default ReportDetail;
