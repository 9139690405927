// 전체 회원리스트 페이지에서 사용 중인 카운트버튼
// ex. 신고한, 신고당한, 차단한, 차단당한
import axios from 'axios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { API } from '../config';
import ModalPortal from '../ModalPotal';

interface propsType {
  // 해당 데이터의 숫자 정보 (ex. 차단한 횟수)
  count: number;
  // 해당 유저의 pk
  uid: number;
  // 차단한 또는 차단 당한 회원 리스트 정보
  detail?: Array<any>;
}

const CountButton = ({ count, uid, detail }: propsType) => {
  const [xy, setXy] = useState([0, 0]);
  const [showDetail, setShowDetail] = useState(false);

  // 유저 차단 해지 함수
  const unblock = async (id: number) => {
    if (window.confirm('정말 차단을 해제할까요?')) {
      const res = await axios.put(API.USER_LIST, {
        user_id: uid,
        block: id,
      });
      if (res.status === 200) {
        window.alert('해제되었습니다.');
        window.location.replace('');
        return;
      }
      window.alert('일시적인 오류입니다.');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => setShowDetail(false));
    return window.removeEventListener('scroll', () => setShowDetail(false));
  });

  return (
    // 모달로 보여줄 정보의 유무에 따라 hasDetail 값 결정
    <Container hasDetail={detail != null}>
      <Count
        count={count}
        onClick={(e) => {
          if (detail && detail.length > 0) {
            setXy([e.clientX, e.clientY]);
            setShowDetail(true);
          }
        }}
      >
        {count}
      </Count>
      {detail && showDetail ? (
        <ModalPortal>
          <Modal
            position={xy}
            posCenter={typeof detail[0] != 'string'}
            onMouseLeave={() => setShowDetail(false)}
          >
            {detail.map((el, i) =>
              typeof el === 'string' ? (
                <Label key={`string${i}`}>{el}</Label>
              ) : (
                <>
                  <Span key={`${i}n`}>{el.nickname}</Span>
                  <Span key={`${i}p`}>{el.phone}</Span>
                  <Span key={`${i}d`}>{el.date}</Span>
                  <Btn onClick={() => unblock(el.block_id)}>❌</Btn>
                </>
              )
            )}
          </Modal>
        </ModalPortal>
      ) : null}
    </Container>
  );
};

export const Container = styled.div<{ hasDetail: boolean }>`
  position: relative;
  width: fit-content;
  margin: auto;
  cursor: ${(props) => props.hasDetail && 'pointer'};
`;

export const Count = styled.div<{ count: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin: auto;
  border-radius: 5px;
  background-color: ${(props) =>
    props.count >= 3 ? props.theme.orange : props.theme.lightOrange};
  color: ${(props) => (props.count >= 3 ? 'white' : props.theme.orange)};
  font-size: 12px;
  font-weight: 800;
`;

export const Modal = styled.div<{
  position: Array<number>;
  posCenter: boolean;
}>`
  position: fixed;
  left: ${(props) => props.position[0]}px;
  top: ${(props) => props.position[1]}px;
  transform: translateX(-50%);
  width: max-content;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  border: 2px solid ${({ theme }) => theme.orange};
  z-index: 9;

  @media ${({ theme }) => theme.mobile} {
    ${(props) =>
      props.posCenter &&
      `position: fixed;
      top: 50vh;
      left: 50vw;
      transform: translate(-50%, -50%);`}
  }
`;

export const Label = styled.p`
  line-height: 1.5;
`;

export const Span = styled.span`
  margin: 5px;
  padding: 2px 5px;
`;

export const Btn = styled.button`
  margin-left: 5px;
`;

export default CountButton;
