import styled from 'styled-components';
import theme from '../styles/theme';

interface propsType {
  // 프로필 사진의 크기
  size: number;
  // 프로필 이미지 url
  src?: string;
}

const Profile = ({ size, src }: propsType): JSX.Element => {
  return (
    <ProfileWrapper size={size}>
      {src && <img src={src} alt="user avatar" width="100%" height="100%" />}
    </ProfileWrapper>
  );
};

const ProfileWrapper = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  margin: auto;
  border-radius: 100%;
  background-color: ${theme.lightGray};
  overflow: hidden;
  flex-shrink: 0;
`;

export default Profile;
