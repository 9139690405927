// 로그인 페이지
import { useState } from 'react';
import Button from '../../components/Button';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import axios from 'axios';
import { API } from '../../config';
import { ReactComponent as People } from '../../asset/people.svg';

const Signin = () => {
  const navigate = useNavigate();
  // 비밀번호 숨김 여부 state
  const [showPW, setShowPW] = useState<boolean>(false);
  // 입력값 state
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });

  // 입력 관련 함수
  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  // 로그인 요청 함수
  const handleSignin = async (e: any) => {
    const { email, password } = inputs;
    e.preventDefault();
    axios.post(API.SIGNIN, { email, password }).then((res: any) => {
      if (res.status === 200) {
        localStorage.setItem('accessToken', res.data.data.token);
        navigate(`/`);
        window.scrollTo(0, 0);
      } else {
        return alert('비밀번호를 확인하고 다시 시도해 주세요');
      }
    });
  };

  // 비밀번호 숨김 온오프
  const toggleShowPW = (e: any) => {
    e.stopPropagation();
    setShowPW((prev) => !prev);
  };

  return (
    <InputWrapper onSubmit={handleSignin}>
      <People width="60%" />
      <input
        name="email"
        autoComplete="username"
        type="email"
        placeholder="이메일 주소를 입력해 주세요"
        onChange={handleInput}
      />
      <PasswardInput>
        <input
          name="password"
          type={showPW ? 'text' : 'password'}
          placeholder="비밀번호를 입력해 주세요"
          onChange={handleInput}
        />
        <Button onClick={toggleShowPW} type="button">
          {showPW ? '🔐' : '🔓'}
        </Button>
      </PasswardInput>
      <Button color="primary200" fullWidth type="submit">
        로그인
      </Button>
    </InputWrapper>
  );
};

const InputWrapper = styled.form`
  ${({ theme }) => theme.flexColumnSet()};
  gap: 1rem;
  width: 600px;
  padding-top: 10rem;

  @media screen and (max-width: 650px) {
    width: 90%;
  }

  & > h1 {
    font-size: 2rem;
    font-weight: 500;
    margin-right: auto;
  }

  & > input {
    width: 100%;
    padding: 0.6rem;
    font-size: 1rem;

    border: 1px solid ${({ theme }) => theme.colors.gray100};
    border-radius: 4px;
    -webkit-appearance: none;
  }
`;

const PasswardInput = styled.div`
  position: relative;
  width: 100%;

  & > input {
    width: 100%;
    padding: 0.6rem;
    font-size: 1rem;

    border: 1px solid ${({ theme }) => theme.colors.gray100};
    border-radius: 4px;
    -webkit-appearance: none;
  }

  & > button {
    position: absolute;
    top: 50%;
    right: 0%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: transparent;
  }
`;
export default Signin;
