// 관리자 전체 페이지 레이아웃 컴포넌트
// 라우트 파일의 children이 Outlet 위치로 렌더됨
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router";
import styled from "styled-components";
import Header from "./Header";
import { useLocation } from "react-router";

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      return navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <Header></Header>
      <Contents>
        <Wrapper>
          <Outlet />
        </Wrapper>
      </Contents>
    </>
  );
};

const Contents = styled.div`
  ${({ theme }) => theme.flexSet()};
`;

const Wrapper = styled.div`
  ${({ theme }) => theme.flexSet("center", "flex-start")};
  width: 1400px;
  min-height: 100vh;
  overflow: hidden;
`;

export default Layout;
