import styled from "styled-components";

const flexSet = (just = "center", align = "center") => {
  return `display: flex;
  justify-content: ${just};
  align-items: ${align};`;
};

const flexColumnSet = (just = "center", align = "center") => {
  return `display: flex;
  flex-direction: column;
  justify-content: ${just};
  align-items: ${align};`;
};

const posCenterX = (type = "absolute") => {
  return `
  position: ${type};
  left:50%;
  transform:translateX(-50%);
  `;
};

const posCenterY = (type = "absolute") => {
  return `
  position: ${type};
  top: 50%;
  transform: translateY(-50%);
  `;
};

const posCenter = (type = "absolute") => {
  return `
  position: ${type};
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  `;
};

const colors = {
  primary100: "#FFAF89",
  primary200: "#f8824a",
  primary300: "#f75f16",
  gray100: "#e7e7e7",
  gray200: "#BCBABA",
  gray300: "#8D8D8D",
  pending: "#FFE37C",
  reject: "#FFAABF",
  done: "#D3E9EB",
  white: "#fff",
  black: "#252525",
};

const theme = {
  orange: "#f8824a",
  deepOrange: "#f75f16",
  lightOrange: "#ffead8",
  lightGray: "#e7e7e7",
  colors,
  mobile: "screen and (max-width: 768px)",
  flexSet,
  flexColumnSet,
  posCenterX,
  posCenterY,
  posCenter,
};

export default theme;

export const Highlight = styled.p`
  display: inline-block;
  width: fit-content;
  padding: 3px 10px;
  background-color: ${theme.orange};
  border-radius: 20px;
  color: white;
`;

export const Gender = styled.div<{ gender: number; noMargin?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 20px;
  padding: 6px;
  margin: ${(props) => (props.noMargin ? "5px" : "auto")};
  border-radius: 5px;
  background-color: ${(props) => (props.gender === 1 ? "skyblue" : "pink")};
  color: white;
  font-size: 12px;
  font-weight: 900;
`;

export const TextButton = styled.button`
  display: block;
  margin-left: auto;
  padding: 8px 15px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.orange};
  color: white;
  font-weight: 700;
`;
