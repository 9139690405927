import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

// 로그인 정보를 담고 있는 state
export const userInfoState = atom({
  key: 'userInfoState',
  default: {
    accessToken: '',
    refreshToken: '',
  },
  effects_UNSTABLE: [persistAtom],
});

// 회원정보 변경시, 선택된 계정 상태를 담고 있는 state
export const selectedAccountState = atom<{
  ban_type?: number;
  message?: string;
}>({
  key: 'selectedAccountState',
  default: {},
});

export const optionState = atom<Object>({
  key: 'optionState',
  default: {},
});
