import styled from 'styled-components';

interface propsType {
  // 완료 === true, 처리 전 === false
  state: boolean;
}

const StateBadge = ({ state }: propsType) => {
  let color: string = 'red';
  let text: string = '처리 전';

  switch (state) {
    case true:
      color = 'orange';
      text = '완료';
      break;
    case false:
      color = 'red';
      text = '처리 전';
      break;
  }

  return <Badge color={color}>{text}</Badge>;
};

const Badge = styled.div<{ color: string }>`
  ${({ theme }) => theme.flexSet()}
  height: 20px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  color: white;
  font-weight: 600;
  font-size: 0.8rem;
`;

export default StateBadge;
