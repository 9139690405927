// 계정상태를 보여주는 badge
import styled from 'styled-components';

interface propsType {
  state: number;
}

const UserStateBadge = ({ state }: propsType) => {
  let color: string = 'yellow';
  let text: string = '활성';

  switch (state) {
    case 1:
      color = 'orange';
      text = '활성';
      break;
    case 2:
      color = 'gray';
      text = '탈퇴';
      break;
    case 3:
      color = 'red';
      text = '3일 정지';
      break;
    case 4:
      color = 'black';
      text = '영구정지';
      break;
  }

  return <Badge color={color}>{text}</Badge>;
};

const Badge = styled.div<{ color: string }>`
  ${({ theme }) => theme.flexSet()}
  height: 20px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  color: white;
  font-weight: 600;
  font-size: 0.8rem;
`;

export default UserStateBadge;
