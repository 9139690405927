// 문의 내역 상세 페이지
import { FormEventHandler, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import emailjs from "emailjs-com";
import axios from "axios";
import Loading from "../../components/Loading";
import Profile from "../../components/Profile";
import { Inquiry } from "../../interfaces/Interfaces";
import { API } from "../../config";
import { TextButton } from "../../styles/theme";
import UserDetail from "../../components/UserDetail";

const InquiryDetail = () => {
  const { id } = useParams();
  const answer = useRef<string>();

  const [inquiry, setInquiry] = useState<Inquiry>();

  const [showDetail, setShowDetail] = useState(false);

  const getInqury = () => {
    axios.get(`${API.INQUIRY}/${id}`).then((res) => {
      setInquiry(res.data.data);
    });
  };

  const reply: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (window.confirm("답변을 전송할까요?")) {
      emailjs
        .sendForm(
          "wena",
          "template_r3kcsup",
          e.currentTarget,
          "user_0sw7eoDOk7W1qV2WKHODb"
        )
        .then(
          (res) => {
            saveReply();
          },
          (err) => {
            alert("오류가 발생했습니다. 잠시후에 다시 시도해주세요.");
          }
        );
    }
  };

  const saveReply = () => {
    axios.post(`${API.ANSWER}/${id}`, { contents: answer.current }).then(
      (res) => {
        window.confirm("답변이 전송되었습니다.");
        getInqury();
      },
      (err) => {
        alert("오류가 발생했습니다.");
      }
    );
  };

  useEffect(getInqury, [id]);

  return inquiry ? (
    <Container>
      <ShadowBox>
        <Title>
          {inquiry.title}
          <UserInfo
            onClick={(e) => {
              setShowDetail(true);
            }}
          >
            {showDetail && (
              <UserDetail
                id={inquiry.user_id}
                onClose={() => setShowDetail(false)}
              />
            )}
            <Text>
              <p>{inquiry.nickname}</p>
              <p>{inquiry.email}</p>
            </Text>
            <Profile size={35} src={inquiry.profile} />
          </UserInfo>
        </Title>
        <Contents bottom>
          <p>문의 내용</p>
          {inquiry.contents}
        </Contents>
        {inquiry.answer ? (
          <Contents>
            <p>답변 내역</p>
            {inquiry.answer}
          </Contents>
        ) : (
          <form onSubmit={reply}>
            <input type="hidden" name="enquiry" value={inquiry.contents} />
            <input type="hidden" name="user_email" value={inquiry.email} />
            <AnswerForm
              name="message"
              placeholder="답변을 작성해주세요"
              onChange={(e) => {
                answer.current = e.target.value;
              }}
            ></AnswerForm>
            <TextButton type="submit">답변 전송</TextButton>
          </form>
        )}
      </ShadowBox>
    </Container>
  ) : (
    <Loading />
  );
};

const Container = styled.div`
  min-width: 1000px;
  padding: 10px;

  @media ${({ theme }) => theme.mobile} {
    width: 100vw;
  }
`;

const ShadowBox = styled.div`
  width: 100%;
  position: relative;
  padding: 25px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 0px 6px lightgray;

  @media ${({ theme }) => theme.mobile} {
    padding: 10px;
  }
`;

const Title = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 500;

  @media ${({ theme }) => theme.mobile} {
    padding: 10px;
  }
`;

const UserInfo = styled.div`
  position: relative;
  ${({ theme }) => theme.flexSet()}
  width: fit-content;
  margin-left: auto;
  padding: 10px 0px;
  cursor: pointer;
`;

const Text = styled.div`
  margin-right: 10px;
  font-size: 0.9rem;
  text-align: right;
  line-height: 1.25;
`;

const Contents = styled.div<{ bottom?: boolean }>`
  margin-top: 20px;
  margin-bottom: ${(props) => (props.bottom ? 60 : 20)}px;

  p {
    color: ${({ theme }) => theme.orange};
    font-weight: 800;
    margin: 20px 0px;
  }

  @media ${({ theme }) => theme.mobile} {
    padding: 10px;
  }
`;

const AnswerForm = styled.textarea`
  display: block;
  width: 100%;
  height: 250px;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid lightgray;
  outline-color: ${({ theme }) => theme.orange};
  resize: none;
`;

export default InquiryDetail;
