import axios from 'axios';
import { useEffect, useState } from 'react';
import Profile from '../../../components/Profile';
import Table from '../../../components/Table';
import { API } from '../../../config';
import { Head, User } from '../../../interfaces/Interfaces';
import { Gender } from '../../../styles/theme';

interface propTypes {
  count: number;
}

const UserPreview = ({ count }: propTypes) => {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    axios.get(`${API.USER_LIST}?page=1`).then((res) => {
      setUsers(res.data.data.slice(0, 5));
    });
  }, []);

  return (
    <Table
      title="👨‍👩‍👧‍👦 신규 유저"
      heads={HEAD_INFO}
      data={users.map((user) => [
        user.id,
        <Profile size={30} src={user.profile} />,
        user.nickname,
        <Gender gender={user.gender}>{user.gender === 1 ? 'M' : 'F'}</Gender>,
        user.date_of_birth,
      ])}
      totalItems={count}
    />
  );
};

export default UserPreview;

const HEAD_INFO: Array<Head> = [
  {
    id: 0,
    title: 'user_pk',
    options: [{ id: 0, label: 'ID' }],
  },
  {
    id: 1,
    title: 'profile',
    options: [{ id: 0, label: '프로필' }],
  },
  {
    id: 2,
    title: 'username',
    options: [{ id: 0, label: '닉네임' }],
  },
  {
    id: 3,
    title: 'gender',
    options: [{ id: 0, label: '성별' }],
  },
  {
    id: 4,
    title: 'birth',
    options: [{ id: 0, label: '생년월일' }],
  },
];
